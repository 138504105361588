
.blogDetailsContainer h1,
.blogDetailsContainer h2,
.blogDetailsContainer h3,
.blogDetailsContainer h4,
.blogDetailsContainer h5,
.blogDetailsContainer h6 {
    font-weight: bold;
    text-transform: none;
}

.blogDetailsContainer h1 {
    font-size: 48px;
    line-height: 52px;
}

.blogDetailsContainer h2 {
    font-size: 40px;
    line-height: 44px;
}

.blogDetailsContainer h3 {
    font-size: 32px;
    line-height: 36px;
}

.blogDetailsContainer h4 {
    font-size: 28px;
    line-height: 32px;
}

.blogDetailsContainer h5 {
    font-size: 24px;
    line-height: 28px;
}

.blogDetailsContainer h6 {
    font-size: 20px;
    line-height: 24px;
}

@media (min-width: 768px) {
    .blogDetailsContainer h1 {
        font-size: 60px;
        line-height: 64px;
    }
}
